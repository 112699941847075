































































































































































































import Vue from 'vue';
import { mapState } from 'vuex';
import SettingsDialogDoctors from '@/components/modules/SettingsDialogDoctors.vue';
import SettingsDialogPractice from '@/components/modules/SettingsDialogPractice.vue';
import SettingsDialogTreatments from '@/components/modules/SettingsDialogTreatments.vue';
import {mdiClose} from '@mdi/js';
import {boolean} from 'boolean';
import AppointmentTreatmentSelector from '@/components/modules/AppointmentTreatmentSelector.vue';

export default Vue.extend({
  name: 'SettingsDialog',
  components: {
    AppointmentTreatmentSelector,
    SettingsDialogDoctors,
    SettingsDialogPractice,
    SettingsDialogTreatments
  },
  data() {
    return {
      dialog: true,
      activeTab: 'doctors',
      closeIcon: mdiClose,
      showAddItem: false,
      allowedToClose: true,
      input: {
        doctorName: '',
        doctorTreatments: [],
        doctorCandidates: undefined,
        roomName: '',
        roomTreatments: [],
        treatmentName: '',
        treatmentFullName: '',
        treatmentDuration: '30',
        treatmentRooms: [],
      },
      defaults: {
        doctorName: '',
        doctorTreatments: [],
        roomName: '',
        roomTreatments: [],
        treatmentName: '',
        treatmentFullName: '',
        treatmentDuration: '30',
        treatmentRooms: [],
      },
      rules: {
        required: value => !!value || 'Required.',
        maxLength: value => value.length <= 30 || 'Max 30 characters',
        maxShort: value => value.length <= 6 || 'Max 6 characters'
      }
    };
  },
  computed: {
    ...mapState({
      doctors: (state: any) => state.doctors,
      rooms: (state: any) => state.rooms,
      treatments: (state: any) => state.treatments,
      doctorCandidates: (state: any) => state.doctorCandidates,
      // @ts-ignore
      offices: state => state.auth.user?.offices,
    }),
    allRoomsUUID() {
      return this.rooms.map(({uuid}) => uuid);
    },
    newDoctor() {
      if (this.activeTab !== 'doctors') return null;
      return {
        office: this.$store.state.auth.office,
        name: this.input.doctorName,
        treatments: this.input.doctorTreatments.length ? this.input.doctorTreatments : [],
        externalId: this.input.doctorCandidates ? this.input.doctorCandidates : undefined,
      }
    },
    newRoom() {
      if (this.activeTab !== 'rooms') return null;
      return {
        office: this.$store.state.auth.office,
        name: this.input.roomName,
        treatments: this.input.roomTreatments.length ? this.input.roomTreatments : [],
      }
    },
    newTreatment() {
      if (this.activeTab !== 'treatments') return null;
      return {
        office: this.$store.state.auth.office,
        full_name: this.input.treatmentFullName,
        name: this.input.treatmentName,
        duration: this.input.treatmentDuration,
        rooms: this.input.treatmentRooms.length ? this.input.treatmentRooms : [],
      }
    },
    selectedOfficeName() {
      const officeUUID = this.$store.state.auth.office;
      const title = this.offices.filter( eachOffice => eachOffice.uuid === officeUUID )[0].name
      return (title.length > 35) ? title.substr(0, 35-1) + '...' : title;
    },
  },
  watch: {
    activeTab() {
      if (this.activeTab === 'doctors') {
        this.$store.dispatch('GET_ALL_DOCTORS_CANDIDATES');
      }
    },
    'input.treatmentFullName': 'createShorthand'
  },
  mounted() {
    this.fetchData();
    if (this.activeTab === 'doctors') {
      this.$store.dispatch('GET_ALL_DOCTORS_CANDIDATES');
    }
  },
  methods: {
    closeModal() {
      if (this.allowedToClose) {
        this.$emit('close-dialog');
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Please, save your changes before closing modal' });
      }
    },
    setAllowedToClose(value: boolean) {
      this.allowedToClose = value;
    },
    async fetchData() {
      await Promise.all([
        this.$store.dispatch('LOAD_DOCTORS'),
        this.$store.dispatch('LOAD_TREATMENTS'),
        this.$store.dispatch('LOAD_ROOMS'),
        this.$store.dispatch('LOAD_DASHBOARD')
      ])
    },
    createShorthand(str) {
      const words = str.split(' ');

      let firstWord = words[0].replace(/[^bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ]+(.{0,2}).*/, '$1');

      const shortenedWords = words.slice(1).map(word => word[0]).join('').toUpperCase();

      firstWord = `${firstWord.charAt(0).toUpperCase()}${firstWord.slice(1)}`;
      let result = [firstWord, shortenedWords].join('-');

      if (result.length > 6) {
        result = [firstWord.substr(2,10).toUpperCase(), shortenedWords].join('-');
      }

      this.input.treatmentName = result;
    },
    async createItem() {
      const options = {
        doctors: this.newDoctor,
        rooms: this.newRoom,
        treatments: this.newTreatment
      }
      const actions = {
        doctors: 'CREATE_DOCTOR',
        rooms: 'CREATE_ROOM',
        treatments: 'CREATE_TREATMENT'
      }
      const payload = { params: options[this.activeTab] };
      const success = await this.$store.dispatch(actions[this.activeTab], payload);
      if (success) {
        this.resetNewItems();
        this.$eventBus.$emit('emit-success', { state: true, text: 'Created new item.' });
        await this.fetchData();
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
    },
    resetNewItems() {
      this.input = Object.assign(this.input, this.defaults);
      this.showAddItem = false;
    },
  }
});
