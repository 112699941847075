
import Vue from "vue";

export default Vue.extend({
  name: "DoctorReviewLink",
  props: {
    doctor: Object,
  },
  data() {
    return {
      dialog: true,
      appendIcon: undefined,
      preview: false,
      doctorName: "",
      doctorReview: "",
      doctorReviewHint: undefined,
      doctorReviewColor: undefined,
    };
  },
  created() {
    this.doctorName = this.doctor.name;
    this.doctorReview = this.doctor.review_widget_class;
  },
  methods: {
    async updateReviewLink() {
      const payload = {
        doctorId: this.doctor.uuid,
        params: {
          name: this.doctorName,
          review_widget_class: this.doctorReview,
        },
      };

      const success = await this.$store.dispatch(
        "UPDATE_DOCTOR_SETTINGS",
        payload
      );
      if (success) {
        this.$eventBus.$emit("emit-success", {
          state: true,
          text: `Review link of ${this.doctor.name} updated successfully`,
        });
        this.$emit("close-dialog");
      } else {
        this.$eventBus.$emit("emit-error", {
          state: true,
          text: "Something went wrong. Please try again.",
        });
      }
    },
  },
});
