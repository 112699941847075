


































import {mapState} from 'vuex';

export default {
  name: "DoctorChronoDialog",
  props: {
    isOpenedDialog: Boolean,
    doctorName: String,
    doctorId: Number,
    doctorUUID: String,
    event: String,
  },
  data() {
    return {
      externalId: undefined,
    }
  },
  computed: {
    ...mapState({
      doctorCandidates: (state: any) => state.doctorCandidates,
    }),
    title() {
      let title: String;
      if (this.event === 'connect') title = 'Connect a doctor';
      if (this.event === 'change') title = 'Change connected doctor';
      return title;
    },
    doctorNameTitle() {
      return `${this.doctorName}, ID: ${this.doctorId}`;
    },
  },
  methods: {
    async connectToDrChrono() {
      const payload = {
        doctorId: this.doctorUUID,
        params: {
          name: this.doctorName,
          externalId: this.externalId
        }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_SETTINGS', payload);
      if (success) {
        this.$eventBus.$emit('emit-success', {state: true, text: 'Doctor connected to DrChrono.'});
        this.$emit('close');
      } else {
        this.$eventBus.$emit('emit-error', {state: true, text: 'Something went wrong. Please try again.'});
      }
    }
  },
}
