








































































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  name: 'SettingsTreatmentCard',
  props: {
    treatment: Object,
  },
  data() {
    return {
      fullNameAppendIcon: undefined,
      nameAppendIcon: undefined,
      durationAppendIcon: undefined,
      treatmentFullNameHint: undefined,
      treatmentNameHint: undefined,
      treatmentDurationHint: undefined,
      treatmentFullNameColor: undefined,
      treatmentNameColor: undefined,
      treatmentDurationColor: undefined,
      treatmentFullName: '',
      treatmentName: '',
      treatmentDuration: '',
      enabled: null,
    };
  },
  computed: mapState(['confirmation']),
  watch: {
    confirmation({ uuid, wasConfirmed }) {
      if (uuid===this.treatment.uuid && wasConfirmed !== null) {
        this.$store.dispatch('RESET_CONFIRMATION')
        if (wasConfirmed) this.deleteTreatment()
      }
    },
    treatmentFullName() {
      if (this.treatmentFullName === this.treatment.full_name) {
        this.$emit('set-allowed-close', true);
      } else {
        this.$emit('set-allowed-close', false);
        this.treatmentFullNameColor = 'yellow darken-1';
        this.treatmentFullNameHint = 'NOT SAVED';
      }
    },
    treatmentName() {
      if (this.treatmentName === this.treatment.name) {
        this.$emit('set-allowed-close', true);
      } else {
        this.$emit('set-allowed-close', false);
        this.treatmentNameColor = 'yellow darken-1';
        this.treatmentNameHint = 'NOT SAVED';
      }
    },
    treatmentDuration() {
      if (this.treatmentDuration === this.treatment.duration) {
        this.$emit('set-allowed-close', true);
      } else {
        this.$emit('set-allowed-close', false);
        this.treatmentDurationColor = 'yellow darken-1';
        this.treatmentDurationHint = 'NOT SAVED';
      }
    },
  },
  created() {
    this.treatmentFullName = this.treatment.full_name;
    this.treatmentName = this.treatment.name;
    this.treatmentDuration = this.treatment.duration;
    this.enabled = (this.treatment.is_enabled === 'yes');
  },
  methods: {
    ...mapActions([
      'ASK_FOR_CONFIRMATION',
      'RESET_CONFIRMATION'
    ]),
    async updateTreatment(event, key) {
      console.log({ event });
      const payload = {
        treatmentId: this.treatment.uuid,
        params: {
          full_name: this.treatmentFullName,
          name: this.treatmentName,
          duration: this.treatmentDuration
        }
      };
      const success = await this.$store.dispatch('UPDATE_TREATMENT_NAME', payload);
      if (success) {
          if (key === 'full_name') {
            this.treatmentFullNameHint = `Updated full name`;
            this.treatmentFullNameColor = 'green darken-1';
          } else if(key === 'name') {
            this.treatmentNameHint = `Updated shorthand`;
            this.treatmentNameColor = 'green darken-1';
          } else {
            this.treatmentDurationHint = `Updated Duration`;
            this.treatmentDurationColor = 'green darken-1';
          }
          this.$emit('set-allowed-close', true);
          await this.$store.dispatch('LOAD_TREATMENTS');
          this.treatmentName = this.treatment.name;
          this.treatmentFullName = this.treatment.full_name;
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }

      setTimeout(() => {
        this.resetTreatmentNameInput();
      }, 1500);
    },

    async toggleTreatmentStatus(enabled) {
      const payload = {
        status: enabled ? 'enable' : 'disable',
        treatmentId: this.treatment.uuid,
      }
      const success = await this.$store.dispatch('TOGGLE_TREATMENT', payload);
      await this.$store.dispatch('LOAD_TREATMENTS');
    },

    resetTreatmentNameInput() {
      this.treatmentFullNameHint = undefined;
      this.treatmentFullNameColor = undefined;
      this.treatmentNameHint = undefined;
      this.treatmentNameColor = undefined;
      this.$eventBus.$emit('emit-error', { state: false, text: '' });
      this.$refs.treatmentFullNameInput.blur();
      this.$refs.treatmentNameInput.blur();
      this.$refs.treatmentDurationInput.blur();
    },

    showConfirmation() {
      this.ASK_FOR_CONFIRMATION({
        message: 'This treatment will be deleted forever',
        uuid: this.treatment.uuid
      });
    },

    async deleteTreatment() {
      const payload = {
        treatmentId: this.treatment.uuid,
        params: { user: this.$store.state.auth.user.uuid }
      }
      const success = await this.$store.dispatch('DELETE_TREATMENT', payload);
      if (success) {
        await this.$store.dispatch('LOAD_TREATMENTS');
        this.treatmentName = this.treatment.name;
        this.enabled = (this.treatment.is_enabled === 'yes');
        this.$eventBus.$emit('emit-success', { state: true, text: 'Deleted' });
        setTimeout(() => {
          this.$eventBus.$emit('emit-success', { state: false, text: '' });
        }, 1200);
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
    },
  }
})
