var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-top":"0.5px solid #A2A2A2","border-radius":"0"},attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-2"},[_c('div',{staticClass:"flex justify-between items-center mb-4"},[_c('div',{staticClass:"flex items-center"},[_c('v-text-field',{ref:"treatmentFullNameInput",staticStyle:{"width":"40%","max-width":"280px","margin-right":"24px"},attrs:{"append-icon":_vm.fullNameAppendIcon,"color":_vm.treatmentFullNameColor,"hint":_vm.treatmentFullNameHint,"label":"Full name","inline":"","outlined":"","persistent-hint":!!_vm.treatmentFullNameHint},on:{"focus":function($event){_vm.fullNameAppendIcon = 'mdi-checkbox-marked-circle'},"blur":function($event){_vm.fullNameAppendIcon = undefined},"click:append":function($event){return _vm.updateTreatment($event, 'full_name')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"display":"inline-block","padding":"0","min-width":"unset","height":"unset"},on:{"click":function($event){return _vm.updateTreatment($event, 'full_name')}}},'v-btn',attrs,false),on),[(_vm.fullNameAppendIcon)?_c('v-icon',{attrs:{"color":"#2F6B93"}},[_vm._v(" mdi-checkbox-marked-circle")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Tap to accept")])])]},proxy:true}]),model:{value:(_vm.treatmentFullName),callback:function ($$v) {_vm.treatmentFullName=$$v},expression:"treatmentFullName"}}),_c('v-text-field',{ref:"treatmentNameInput",staticStyle:{"max-width":"140px","margin-right":"24px"},attrs:{"append-icon":_vm.nameAppendIcon,"color":_vm.treatmentNameColor,"hint":_vm.treatmentNameHint,"label":"Shorthand","inline":"","outlined":"","persistent-hint":!!_vm.treatmentNameHint},on:{"focus":function($event){_vm.nameAppendIcon = 'mdi-checkbox-marked-circle'},"blur":function($event){_vm.nameAppendIcon = undefined},"click:append":function($event){return _vm.updateTreatment($event, 'name')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"display":"inline-block","padding":"0","min-width":"unset","height":"unset"},on:{"click":function($event){return _vm.updateTreatment($event, 'name')}}},'v-btn',attrs,false),on),[(_vm.nameAppendIcon)?_c('v-icon',{attrs:{"color":"#2F6B93"}},[_vm._v(" mdi-checkbox-marked-circle")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Tap to accept")])])]},proxy:true}]),model:{value:(_vm.treatmentName),callback:function ($$v) {_vm.treatmentName=$$v},expression:"treatmentName"}}),_c('v-text-field',{ref:"treatmentDurationInput",staticStyle:{"max-width":"140px"},attrs:{"append-icon":_vm.durationAppendIcon,"color":_vm.treatmentDurationColor,"hint":_vm.treatmentDurationHint,"label":"Duration","inline":"","outlined":"","persistent-hint":!!_vm.treatmentDurationHint},on:{"focus":function($event){_vm.durationAppendIcon = 'mdi-checkbox-marked-circle'},"blur":function($event){_vm.durationAppendIcon = undefined},"click:append":function($event){return _vm.updateTreatment($event, 'duration')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"display":"inline-block","padding":"0","min-width":"unset","height":"unset"},on:{"click":function($event){return _vm.updateTreatment($event, 'duration')}}},'v-btn',attrs,false),on),[(_vm.durationAppendIcon)?_c('v-icon',{attrs:{"color":"#2F6B93"}},[_vm._v(" mdi-checkbox-marked-circle")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Tap to accept")])])]},proxy:true}]),model:{value:(_vm.treatmentDuration),callback:function ($$v) {_vm.treatmentDuration=$$v},expression:"treatmentDuration"}})],1),_c('div',{staticClass:"flex justify-end items-start self-start",staticStyle:{"height":"86px"}},[_c('v-switch',{staticStyle:{"margin-top":"4px"},attrs:{"color":"#66AB99"},on:{"change":function($event){return _vm.toggleTreatmentStatus($event)}},model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":_vm.showConfirmation}},[_c('v-icon',{attrs:{"size":24}},[_vm._v("mdi-delete")])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }