
import Vue from 'vue';

import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default Vue.extend({
  name: 'DoctorBio',
  components: {
    quillEditor
  },
  props: {
    doctor: Object,
  },
  data() {
    return {
      dialog: true,
      appendIcon: undefined,
      preview: false,
      doctorName: '',
      doctorBio: '',
      doctorBioHint: undefined,
      doctorBioColor: undefined,
      doctorBioShort: '',
      doctorBioShortHint: undefined,
      doctorBioShortColor: undefined,
      editorOptions: {
        // Specify Quill editor options here
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            ['link',],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
          ],
        }
      }
    }
  },
  created() {
    this.doctorName = this.doctor.name;
    this.doctorBio = this.doctor.bio;
    this.doctorBioShort = this.doctor.short_bio;
  },
  methods: {
    async updateBio() {
      const payload = {
        doctorId: this.doctor.uuid,
        params: {
          name: this.doctorName,
          bio: this.doctorBio,
          bio_short: this.doctorBioShort,
        }
      };

      const success = await this.$store.dispatch('UPDATE_DOCTOR_SETTINGS', payload);
      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: `Bio of ${this.doctor.name} updated successfully` });
        this.$emit('close-dialog');
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: 'Something went wrong. Please try again.' });
      }
    }
  }
});
