

































































import Vue from 'vue';
import SettingsRoomCard from '@/components/cards/SettingsRoomCard.vue';
import SettingsDoctorCard from "@/components/cards/SettingsDoctorCard.vue";
import draggable from 'vuedraggable';
import equal from 'fast-deep-equal/es6';

export default Vue.extend({
  name: 'SettingsDialogPractice',
  components: {
    SettingsDoctorCard,
    SettingsRoomCard,
    draggable
  },
  props: {
    rooms: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.copyRoomsForDisplay();
  },
  data() {
    return {
      showDialog: false,
      dialogType: null,
      newTreatment: '',
      updatingRoom: null,
      roomColor: null,
      roomName: null,
      roomsList: [],
      swatches: [
        ['#56C2DE',
        '#5047A5'],
        ['#F29E41',
        '#21BA33'],
        ['#DE435B',
        '#1976A8'],
        ['#19A881',
        '#9719A8'],
        ['#FFA558',
        '#82C795']
      ],
    };
  },
  watch: {
    roomsList: 'saveRoomsOrder',
    rooms: 'copyRoomsForDisplay'
  },
  computed: {
    filteredTreatments() {
      if (!this.updatingRoom) return [];
      const room = this.rooms.find(d => d.uuid === this.updatingRoom);
      const roomTreatments = room.treatments.map(t => t.uuid);
      return this.treatments.filter(t => !roomTreatments.includes(t.uuid));
    },
    treatments() {
      return this.$store.state.treatments;
    },
  },
  methods: {
    copyRoomsForDisplay(){
      this.roomsList = JSON.parse(JSON.stringify(this.rooms));
    },
    async saveRoomsOrder() {
      const draggedList = this.getOrder(this.roomsList);
      const oldOrderList = this.getOrder(this.rooms);

      if (!equal(draggedList, oldOrderList)) {

        const success = await this.$store.dispatch('UPDATE_ROOMS_ORDER', {
          params: {
            roomsUuidAr: draggedList
          }
        })

        if (success) {
          this.$eventBus.$emit('emit-success', {state: true, text: 'Order update was successful'});
        } else {
          this.$eventBus.$emit('emit-error', {state: true, text: 'Order update was Failed!'});
        }
      }
    },
    handleShowDialog(payload) {
      this.showDialog = true;
      this.updatingRoom = payload.uuid;
      this.dialogType = payload.type;
      if (payload.color) {
        this.roomColor = payload.color;
        this.roomName = payload.name
      }
    },
    setAllowedToClose(value: boolean) {
      this.$emit('set-allowed-close', value);
    },
    async addTreatmentToRoom() {
      const payload = {
        roomId: this.updatingRoom,
        status: 'add',
        params: { treatment: this.newTreatment }
      };
      const success = await this.$store.dispatch('UPDATE_ROOM_TREATMENT', payload);
      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatment to room.' });
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    async handleAddAllTreatmentsToRoom({ uuid }) {
      this.updatingRoom = uuid
      const successArr = await Promise.all(
        this.filteredTreatments.map(async x =>
          this.$store.dispatch('UPDATE_ROOM_TREATMENT', {
            roomId: uuid,
            status: 'add',
            params: { treatment: x.uuid }
          })
        )
      )

      if (!successArr.every(x => x)) {
        this.$eventBus.$emit('emit-error', { state: true })
      } else {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added treatments to room.' });
      }

    },
    async handleUpdateColor() {
      const payload = {
        roomId: this.updatingRoom,
        params: { color: this.roomColor, name: this.roomName }
      };

      const success = await this.$store.dispatch('UPDATE_ROOM_SETTINGS', payload);
      if (success) {
        this.showDialog = false;
        this.$eventBus.$emit('emit-success', { state: true, text: 'Updated color.' });
      } else {
        this.$eventBus.$emit('emit-error', { state: true });
      }
    },
    getOrder(rooms){
      const roomsUuidAr = [];

      if (rooms.length === 0) { return roomsUuidAr }

      rooms.map((t) => {
        roomsUuidAr.push(t.uuid)
      })

      return roomsUuidAr;
    },
  }
});
