















import Vue from 'vue';
import SettingsTreatmentCard from '@/components/cards/SettingsTreatmentCard.vue';
import SettingsRoomCard from "@/components/cards/SettingsRoomCard.vue";
import {boolean} from "boolean";

export default Vue.extend({
  name: 'SettingsDialogTreatments',
  components: {SettingsRoomCard, SettingsTreatmentCard },
  props: {
    treatments: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showAddTime: false,
      newTime: '',
      currentTreatmentIndex: 0
    };
  },
  methods: {
    setAllowedToClose(value: boolean) {
      this.$emit('set-allowed-close', value);
    },
    removeTreatment(i) {
      this.treatments.splice(i, 1);
    },
    removeTime(treatmentIndex, timeIndex) {
      this.treatments[treatmentIndex].allocatedTimes.splice(timeIndex, 1);
    },
    showAddTimeDialog(treatmentIndex) {
      this.showAddTime = true;
      this.currentTreatmentIndex = treatmentIndex;
    },
    addTime() {
      this.treatments[this.currentTreatmentIndex].allocatedTimes.push(
        this.newTime
      );
      this.showAddTime = false;
      this.newTime = '';
    }
  }
});
